import { Platform } from 'react-native';
import resolveConfig from 'tailwindcss/resolveConfig';
import { Config } from 'tailwindcss';
import tailwindConfig from '../../../../../tailwind.config.js';
import { IFullTheme } from '~/@types/theme/full-theme.d';
import { IBaseTheme } from '~/@types/theme/base-theme.d';
import { colorMixer, getDarkerColor } from '~/utils/colorsTheme';
import { getDefaultTokens } from '~/components/@hello-ui/Theme/theme/tokens';

const tailwindColors = tailwindConfig.theme.extend.colors as IBaseTheme['colors'];

export const StyledDefaultTheme: IFullTheme = {
  partner_full_name: '...',
  partner_name: '...',
  logo: '',
  colors: {
    ...(resolveConfig<Config>(tailwindConfig).theme!.colors as IBaseTheme['colors']),
    white: '#FFFFFF',
    contrast_text: '#FFFFFF',

    // Legacy colors
    primaryDarker: getDarkerColor(tailwindColors.primary),
    primary_alt: 'rgba(252, 92, 92, 1)',
    primary20: 'rgba(242, 97, 68, 0.2)',
    primary40: colorMixer(tailwindColors.primary, '#FFFFFF', 0.4),
    primary50: 'rgba(255, 135, 111, 1)',
    primary60: 'rgba(253, 239, 233, 1)',

    white15: 'rgba(255, 255, 255, 0.15)',
    white25: 'rgba(255, 255, 255, 0.25)',
    white70: 'rgba(255, 255, 255, 0.7)',

    black5: 'rgba(245, 245, 245, 1)',
    black8: 'rgba(236, 235, 235, 1)',
    black16: 'rgba(216, 215, 214, 1)',
    black20: tailwindColors['gray-placeholder'],
    black60: 'rgba(110, 104, 103, 1)',
    black70: 'rgba(97, 97, 97, 1)',
    black80: 'rgba(66, 66, 66, 1)',

    blue20: 'rgba(25, 117, 155, 0.2)',
    blue70: 'rgba(25, 117, 155, 0.7)',

    gray: 'rgba(97, 97, 97, 1)',
    gray10: 'rgba(250, 250, 250, 1)',
    gray15: 'rgba(224,224,224,0.42)',
    gray20: 'rgba(224, 224, 224, 1)',
    gray60: tailwindColors['gray-disabled'],
    gray70: 'rgba(97, 97, 97, 1)',

    link: tailwindColors.primary,
    link10: tailwindColors.primary,

    beige: 'rgba(246, 239, 223, 1)',
    green20: 'rgba(0, 235, 121, 0.2)',
    redlight: 'rgba(252, 228, 223, 1)',
    red: 'rgba(245, 66, 66, 1)',
    buttonDisabled: tailwindColors['orange-disabled'],
    background: tailwindColors['background-gray'],
    backgroundDisabled: tailwindColors['background-orange'],
    input: tailwindColors['gray-placeholder'],
    placeholder: tailwindColors['gray-placeholder'],
    paragraph: tailwindColors['gray-paragraph'],
    title: tailwindColors['black-title'],
    textDisabled: tailwindColors['gray-disabled'],

    red70: tailwindColors.error,
    green: tailwindColors.success,
    blue: tailwindColors.info,
    orange14: tailwindColors.warning,
    warning: tailwindColors.warning,
    caution: tailwindColors.warning,
  },
  footer_phones: [],
  social_links: {
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
  },
  copyright_text: '',
  footer_disclaimers: [],
  card_design_url: '',
  tem_pay_logo_url: '',
  tem_pay_header_logo_url: '',
  pay_name: '...',
  dependents_limit: 4,
  dependents_max_age: 21,
  tokens: getDefaultTokens(),
  platform: Platform,
};
