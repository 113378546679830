import React, { useMemo } from 'react';

import { useNavigation } from '@react-navigation/native';
import { CardList } from '~/components/@hello-ui';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import HouseExclamationIcon from '~/components/Icons/HouseExclamation';
import { OnboardingIcon } from '~/components/Icons/Onboarding';
import TaxiIcon from '~/components/Icons/Taxi';
import UsersIcon from '~/components/Icons/Users';
import { goBack } from '~/helpers';
import { useAuth } from '~/hooks/auth';
import FileMedicalIcon from '~/components/Icons/FileMedical';
import BellIcon from '~/components/Icons/Bell';
import StethoscopeIcon from '~/components/Icons/Stethoscope';

export const BenefitsSeniorAssistance = (): JSX.Element => {
  const navigation = useNavigation();

  const { checkIsEnabledFeature } = useAuth();

  const cards = useMemo(
    () =>
      [
        // {
        //   icon: <FileMedicalIcon width={24} height={24} />,
        //   title: 'Questionário de Saúde',
        //   description: 'Identifique necessidades para cuidados personalizados',
        //   onPress: () => navigation.navigate('BenefitsPetFuneralAssistance'),
        //   visible: checkIsEnabledFeature('senior_assistance'),
        //   iconSideOfTitle: true,
        // },
        // {
        //   icon: <BellIcon width={24} height={24} />,
        //   title: 'Lembretes',
        //   description: 'Alerta diário para rotina tranquila',
        //   onPress: () => navigation.navigate('BenefitsPetEmergencyAppointment'),
        //   visible: checkIsEnabledFeature('senior_assistance'),
        //   iconSideOfTitle: true,
        // },
        {
          icon: <StethoscopeIcon width={24} height={24} />,
          title: 'Consultas e Terapia',
          description: 'Profissionais acessíveis para saúde completa',
          onPress: () => navigation.navigate('SeniorAssistanceSchedule'),
          visible: checkIsEnabledFeature('senior_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <HouseExclamationIcon width={24} height={24} />,
          title: 'Vistoria Residencial',
          description: 'Verificação para garantir um lar seguro.',
          onPress: () => navigation.navigate('SeniorAssistanceResidentialInspection'),
          visible: checkIsEnabledFeature('senior_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <UsersIcon width={24} height={24} />,
          title: 'Acompanhante',
          description: 'Companhia para momentos importantes e seguros',
          onPress: () => navigation.navigate('SeniorAssistanceCompanion'),
          visible: checkIsEnabledFeature('senior_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <TaxiIcon width={24} height={24} />,
          title: 'Leva e Traz',
          description: 'Transporte confiável para o seus agendamentos',
          onPress: () => navigation.navigate('SeniorAssistanceTransport'),
          visible: checkIsEnabledFeature('senior_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <OnboardingIcon width={24} height={24} />,
          title: 'Cuidador',
          description: 'Apoio diário para bem-estar contínuo',
          onPress: () => navigation.navigate('SeniorAssistanceCaregiver'),
          visible: checkIsEnabledFeature('senior_assistance'),
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      variant={PageWithCardVariant.Simple}
      simpleOptions={{
        title: 'Assistência Sênior',
        onBack: () => {
          goBack(navigation, 'SeniorAssistance');
        },
      }}>
      <CardList data={cards} />
    </PageWithCard>
  );
};
