import React from 'react';
import { View } from 'react-native';
import { BenefitsCardsTypes } from '../../types';
import * as S from './styles';
import { BenefitCard } from '~/screens/Dashboard/components/Benefits/components/BenefitCard/BenefitCard';

interface BenefitsCardProps {
  benefits: BenefitsCardsTypes[];
}

export const BenefitCards = ({ benefits }: BenefitsCardProps): JSX.Element => {
  const benefitsWithoutVideoConsultation = benefits.filter(
    (benefit) =>
      (benefit.route !== 'VideoAppointmentsSchedule' && benefit.route !== 'Schedule24h') ||
      (benefit.title === 'Serviços' && benefit.route === 'Schedule24h'),
  );

  const schedule24Card = benefits.find(
    (benefit) => benefit.route === 'Schedule24h' && benefit.title === 'Consulta por Vídeo Agendada',
  );
  const videoAppointmentScheduleCard = benefits.find(
    (benefit) => benefit.route === 'VideoAppointmentsSchedule',
  );

  const restBenefitCards = benefitsWithoutVideoConsultation.map((card) => (
    <BenefitCard card={card} key={card.route} />
  ));

  return (
    <View className="w-full flex-row">
      <S.WrapperContentCard>
        <S.FirstRow>
          {schedule24Card && (
            <BenefitCard
              card={schedule24Card}
              noFixedWidth
              styleWrapper={{ width: 'auto', flex: 1 }}
            />
          )}
          {videoAppointmentScheduleCard && (
            <BenefitCard
              card={videoAppointmentScheduleCard}
              styleWrapper={{ width: 'auto', flex: 1 }}
            />
          )}
        </S.FirstRow>
        {restBenefitCards}
      </S.WrapperContentCard>
    </View>
  );
};
