import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const ExamIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24" fill="none">
      <Path
        d="M22.293 10.707C22.488 10.902 22.744 11 23 11C23.256 11 23.512 10.902 23.707 10.707C24.098 10.316 24.098 9.68401 23.707 9.29301L14.707 0.293006C14.316 -0.0979941 13.684 -0.0979941 13.293 0.293006C12.902 0.684006 12.902 1.31601 13.293 1.70701L14.258 2.67201L1.465 15.464C0.521 16.408 0 17.664 0 19C0 20.336 0.521 21.591 1.465 22.536C2.409 23.481 3.664 24 5 24C6.336 24 7.591 23.48 8.535 22.536L21.328 9.74301L22.293 10.708V10.707ZM7.121 21.122C5.988 22.255 4.012 22.255 2.879 21.122C1.709 19.952 1.709 18.049 2.879 16.879L10.691 9.06701L14.934 13.31L7.122 21.122H7.121ZM16.347 11.896L12.104 7.65301L15.671 4.08601L19.914 8.32901L16.347 11.896ZM21.553 15.634C20.699 14.797 19.303 14.797 18.447 15.634C18.422 15.658 17.172 17.173 17.172 17.173C16.416 17.928 16 18.933 16 20.001C16 21.069 16.416 22.074 17.172 22.829C17.928 23.585 18.932 24.001 20 24.001C21.068 24.001 22.072 23.585 22.828 22.829C23.584 22.074 24 21.069 24 20.001C24 18.933 23.584 17.928 22.892 17.242C22.892 17.242 21.58 15.659 21.554 15.634H21.553ZM21.413 21.415C20.657 22.171 19.341 22.171 18.585 21.415C18.207 21.037 17.999 20.535 17.999 20.001C17.999 19.467 18.207 18.965 18.648 18.518L19.868 17.044C19.948 16.985 20.054 16.987 20.131 17.044L21.413 18.587C21.791 18.965 21.999 19.467 21.999 20.001C21.999 20.535 21.791 21.037 21.413 21.415Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default ExamIcon;
