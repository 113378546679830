import React, { createContext, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { AppointmentTypes, PatientType, TelemedicineAppointmentPayload } from './types';
import { trackingEventType } from './tracking/types';
import { useApi } from '~/hooks/api';
import { useTemPay } from '~/hooks/temPay';
import { useAuth } from '~/auth/legacy/useAuth';
import { AppointmentFlowType, TypesEnum } from '~/enums/appointment';
import { useSweetAlert } from '~/components/@tem-ui';
import { useTracking } from '~/services/tracking';
import { FileType } from '~/components/@tem-ui/Uploader/types';
import { extractDigits } from '~/utils/strings';
import { useDeepLinkStore } from '~/auth/strategies/deep-link/deep-link.store';

export interface ClinicType {
  name: string;
  cnpj: string;
  email: string;
  address: {
    number: string;
    street: string;
    city: string;
    neighbourhood: string;
    state: string;
    zipCode: string;
    lat: string;
    long: string;
  };
}
export interface AppointmentSendPayloadType {
  cpf: string;
  cpf_dependente: string | undefined;
  is_titular: boolean;
  type: 'exam' | 'medical' | 'telemedicine' | 'surgery' | 'checkup';
  status: 'requested' | 'pending';
  address: { state: string; city: string; neighborhood: string[] };
  clinic?: ClinicType;
  days: string[] | null;
  patient_name: string;
  patient_birthdate: string;
  patient_email: string;
  patient_telephone: string;
  card_number: string;
  card_owner: string;
  product_name: string;
  onix: string;
  specialty: {
    id: number;
    name: string;
  } | null;
  source?: string;
  attatchment?: FileType[] | string | null;
  flowType?: AppointmentFlowType;
  description?: string;
  isCheckup?: boolean | undefined;
  claimNumber?: string;
}

export interface AppointmentInstance {
  patient: PatientType | null;
  appointment: AppointmentTypes | null;
  setPatient: React.Dispatch<React.SetStateAction<PatientType | null>>;
  setAppointment: React.Dispatch<React.SetStateAction<AppointmentTypes | null>>;
  submitAppointment: (event: string, specialty?: string, isToThrowError?: boolean) => void;
  scheduleSubmissionStatus: boolean;
  clearAppointments: () => void;
  appointmentLoading: boolean | null;
  scheduleSubmissionResponse: string;
  submitTelemedicineAppointment: () => Promise<void>;
}

export interface AppointmentProviderTypes {
  children: JSX.Element | JSX.Element[];
}

export const AppointmentContext = createContext<AppointmentInstance>({} as AppointmentInstance);

export const AppointmentProvider = ({ children }: AppointmentProviderTypes): JSX.Element => {
  const api = useApi();

  const { user, onixCode, isDependent } = useAuth();
  const { card } = useTemPay();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { tracking } = useTracking();
  const [patient, setPatient] = useState<PatientType | null>(null);
  const [appointment, setAppointment] = useState<AppointmentTypes | null>(null);
  const [scheduleSubmissionStatus, setScheduleSubmissionStatus] = useState<boolean>(false);
  const [scheduleSubmissionResponse, setScheduleSubmissionResponse] = useState();
  const [appointmentLoading, setAppointmentLoading] = useState<boolean>(false);
  const { claimNumber, isDeepLink } = useDeepLinkStore();

  const scheduleTypes = {
    [TypesEnum.Appointment]: 'medical',
    [TypesEnum.Exam]: 'exam',
    [TypesEnum.Surgical]: 'surgery',
    [TypesEnum.CheckUp]: 'checkup',
    default: 'telemedicine',
  };

  const submitTelemedicineAppointment = async (
    appointmentData: AppointmentTypes,
    patientData: PatientType,
  ) => {
    try {
      setAppointmentLoading(true);

      const payload: TelemedicineAppointmentPayload = {
        appointmentDate: format(appointmentData?.appointmentDate, 'dd/MM/yyyy HH:mm'),
        cpf: patientData?.cpf?.replace(/\D/g, ''),
        doctorId: appointmentData?.doctorId,
        prescription: '-',
        onix: String(onixCode?.onix_code),
      };
      const { data } = await api.telemedicineAppointment(payload);

      void tracking.addMovement({
        event: trackingEventType.RequestedInTelemedicineAppointment,
        metadata: {
          appointment: payload,
        },
      });

      setScheduleSubmissionStatus(true);
      setScheduleSubmissionResponse(data.data);
      setAppointmentLoading(null);
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível efetuar seu agendamento.\nQue tal tentar novamente?',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: true,
          buttons: [
            {
              variant: 'primary',
              text: 'Ok',
              onPress: () => {
                hideSweetAlert();
              },
            },
          ],
        },
      );
      throw error;
    } finally {
      setAppointmentLoading(null);
    }
  };

  const submitAppointment = async (
    event: string,
    specialtyName?: string,
    isToThrowError = false,
  ) => {
    setAppointmentLoading(true);

    try {
      const product = onixCode;

      const birthdate = parseISO((patient?.birthdate ?? user?.birthdate).split('T')[0]);
      const patient_birthdate = format(birthdate, 'dd/MM/yyyy');
      let specialty = appointment?.specialty;
      if (!specialty && specialtyName) {
        const { data } = await api.findSpecialtyByName(specialtyName);
        specialty = { ...data, ...(specialtyName === 'psicologia' && { type: 'telemedicine' }) };
        setAppointment({ ...appointment, specialty });
      }

      const payload: AppointmentSendPayloadType = {
        // NAO REMOVER  horaDisponivel E idCalendario- USADO NO CONECTA
        horaDisponivel: appointment?.horaDisponivel,
        idCalendario: appointment?.idCalendario,
        cpf: extractDigits(user?.cpf),
        cpf_dependente: patient?.isTitular
          ? patient?.cpf
          : isDependent
            ? extractDigits(user?.cpf)
            : patient?.cpf,
        is_titular: patient?.isTitular ?? true,
        type: scheduleTypes[appointment?.type] ?? scheduleTypes.default,
        days: appointment?.days ?? null,
        status: 'requested',
        address: appointment?.address ?? { city: '', state: '', neighborhood: [] },
        patient_name: patient?.name ?? user?.name ?? '',
        patient_birthdate,
        patient_email: patient?.email ? patient?.email : user?.email,
        patient_telephone: patient?.phone ?? user?.telephone_1 ?? '',
        card_number: card?.numero_cartao ?? '',
        card_owner: user?.name ?? '',
        product_name: product?.name,
        onix: String(product?.onix_code),
        specialty,
        clinic: appointment?.clinic ?? undefined,
        isCheckup: appointment?.isCheckup,
      };

      if (isDeepLink) {
        payload.claimNumber = claimNumber;
      }

      appointment?.description && (payload.description = appointment.description);
      if (scheduleTypes[appointment?.type] == 'exam') {
        payload.attatchment = appointment?.attatchment ?? [];
      }

      const response = await api.createSchedule(payload);

      if (scheduleTypes[appointment?.type] == 'exam' && response?.data?.response?.protocolo) {
        await api.updateAttachments(
          response?.data?.response.protocolo,
          appointment?.attatchment[0],
          extractDigits(patient?.id ?? user?.cpf),
        );
      }
      void tracking.addMovement({
        event,
        metadata: {
          appointment: payload,
        },
      });

      setScheduleSubmissionStatus(true);
      setScheduleSubmissionResponse(response?.data);
      setAppointmentLoading(null);
      return;
    } catch (err) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível efetuar seu agendamento.\nQue tal tentar novamente?',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: true,
          buttons: [
            {
              variant: 'primary',
              text: 'Ok',
              onPress: () => {
                hideSweetAlert();
              },
            },
          ],
        },
      );

      if (isToThrowError) {
        throw err;
      }
    } finally {
      setAppointmentLoading(false);
    }
  };

  const clearAppointments = () => {
    setPatient(null);
    setAppointment(null);
    setScheduleSubmissionStatus(false);
  };

  return (
    <AppointmentContext.Provider
      value={{
        patient,
        appointment,
        setPatient,
        setAppointment,
        submitAppointment,
        scheduleSubmissionStatus,
        clearAppointments,
        appointmentLoading,
        scheduleSubmissionResponse,
        submitTelemedicineAppointment,
      }}>
      {children}
    </AppointmentContext.Provider>
  );
};
