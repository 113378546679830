import React from 'react';
import Feather from 'react-native-vector-icons/dist/Feather';
import FontAwesome from 'react-native-vector-icons/dist/FontAwesome';
import MaterialCommunityIcons from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';
import { Typography } from '~/components/@tem-ui';
import { Card } from '~/components/@tem-ui/Card';
import * as S from './styles';
import FamilyIcon from './Icons/Family';
import StethoscopeIcon from './Icons/Stethoscope';
import ExamIcon from './Icons/Exam';
import MedicineIcon from './Icons/Medicine';
import ToothIcon from './Icons/Tooth';
import PhonePsycoIcon from './Icons/PhonePsyco';
import CheckUpIcon from './Icons/CheckUpIcon';
import Subsidy from './Icons/Subsidy';
import Surgery from './Icons/Surgery';
import VideoCall from './Icons/VideoCall';
import Phone24 from './Icons/Phone24';

export interface listTypes {
  list: string[];
}

const cardsProps = [
  {
    Icon: () => <FamilyIcon />,
    title: 'Programa Familiar',
    text: 'Igual coração de mãe. Adicione dependentes para terem acesso aos benefícios do programa de saúde.',
    name: 'dependents',
  },
  {
    Icon: () => <StethoscopeIcon />,
    title: 'Consultas',
    text: 'Acesso à uma rede de clínicas médicas com atendimento em todo território nacional.',
    name: 'health_network',
  },
  {
    Icon: () => <ExamIcon />,
    title: 'Exames',
    text: 'Exames laboratoriais e de imagem em excelentes laboratórios com preços reduzidos.',
    name: 'health_network',
  },
  {
    Icon: () => <MedicineIcon />,
    title: 'Medicamentos com desconto',
    text: 'Desconto garantido em mais de 22 mil farmácias por todo o brasil.',
    name: 'medicine_discounts',
  },
  {
    Icon: () => <ToothIcon />,
    title: 'Dentista',
    text: 'Dentistas e ortodentistas para você fazer consultas, exames ou tratamentos odontológicos.',
    name: 'dentistry',
  },
  {
    Icon: ({ color }: string) => <FontAwesome name="mobile" size={22} color={color} />,
    title: 'Teleorientação 24/7',
    text: 'Orientação com profissional de saúde por telefone, a qualquer hora do dia, e qualquer dia da semana.',
    name: 'teleorientation',
  },
  {
    Icon: ({ color }: string) => <Feather name="heart" size={22} color={color} />,
    title: 'Assistência funeral',
    text: 'Em momentos de fragilidade, uma assistência para evitar gastos inesperados.',
    name: 'funeral',
  },
  {
    Icon: ({ color }: string) => (
      <MaterialCommunityIcons name="trophy-outline" size={22} color={color} />
    ),
    title: 'Título de Capitalização',
    text: 'A qualquer momento, seu dia de sorte pode chegar! Sorteios mensais com prêmios em dinheiro.',
    name: 'capitalization_title',
  },
  {
    Icon: ({ color }: string) => <Feather name="percent" size={22} color={color} />,
    title: 'Clube de Descontos',
    text: 'A qualquer momento, seu dia de sorte pode chegar! Sorteios mensais com prêmios em dinheiro.',
    name: 'discount_club',
  },
  {
    Icon: ({ color }: string) => <Feather name="umbrella" size={22} color={color} />,
    title: 'Seguro Morte ou Invalidez por acidente',
    text: 'Um seguro em dinheiro caso algum imprevisto aconteça e comprometa a renda familiar.',
    name: 'life_insurance',
  },
  {
    Icon: ({ color }: string) => <Feather name="home" size={22} color={color} />,
    title: 'Assistência Residêncial',
    text: 'Proteja sua residência com serviços que trazem tranquilidade de viver como você merece.',
    name: 'residential_assistant',
  },
  {
    Icon: ({ color }: string) => <FontAwesome name="credit-card" size={22} color={color} />,
    title: 'Cesta Natalidade',
    text: 'Um vale alimentação no valor de R$ 200,00 para gestantes terem mais uma tranquilidade nesse momento especial.',
    name: 'birth_basket',
  },
  {
    Icon: () => <PhonePsycoIcon />,
    title: 'Terapia Psicológica (por vídeo)',
    text: 'Consultas com profissionais de psicologia feitas por vídeo, pra você cuidar da saúde mental no conforto de casa.',
    name: 'psicologia_online',
  },
  {
    Icon: ({ color }: string) => (
      <MaterialCommunityIcons name="badge-account-horizontal-outline" size={22} color={color} />
    ),
    title: 'Realocação Profissional',
    text: 'Apoio e orientação para você que está procurando se recolocar no mercado profissionalmente.',
    name: 'professional_realocation',
  },
  {
    Icon: () => <CheckUpIcon />,
    title: 'Check-Up',
    text: (
      <S.WrapperTextCard>
        <S.TextCard>
          Check-up nas melhores clínicas e laboratórios para você e sua Família estarem com a saúde
          em dia.
        </S.TextCard>
        <Typography variant="paragraph" color="black70">
          *Quantidade conforme plano contratado
        </Typography>
      </S.WrapperTextCard>
    ),
    name: 'checkup',
  },
  {
    Icon: () => <VideoCall />,
    title: 'Consulta por Vídeo Agendada',
    text: 'O futuro chegou também na medicina. Consultas com comodidade e a facilidade que você precisa',
    name: 'telemedicine',
  },
  {
    Icon: () => <Phone24 />,
    title: 'Consulta por vídeo 24 horas',
    text: 'Consulta com Clínico Geral, feitas só com seu celular. A qualquer dia e a qualquer hora.',
    name: 'telemedicine_24',
  },
  {
    Icon: () => <Surgery />,
    title: 'Cirurgias',
    text: 'Um time especializado te ajuda em todo o processo para agendar cirurgias com preços especiais e muita praticidade.',
    name: 'surgery',
  },
  {
    Icon: () => <Subsidy />,
    title: 'Subsídio em Medicamentos',
    text: 'Até R$ 300,00 por ano de subsídios em medicamentos, pra facilitar ainda mais seus cuidados',
    name: 'subsidy_medicines',
  },
];

export const Cards = ({ list }: listTypes): JSX.Element => {
  const theme = useTheme();
  const cardsFilter = cardsProps.filter((item) => list.find((benefit) => benefit === item.name));

  const style = !theme?.isMobile ? { minHeight: '120px' } : {};

  return (
    <S.WrapperCard>
      {cardsFilter.map((item, idx) => {
        const Icon = item.Icon;
        return (
          <>
            <S.Crads key={idx}>
              <Card testID="cards" key={idx} width={328} height={200} style={{backgroundColor: theme.colors.black5}}>
                <S.WrapperContent>
                  <Icon color={theme.colors.primary} />
                  <Typography variant="body2" color="black" bold={true}>
                    {item.title}
                  </Typography>
                  <S.TextCard style={style}>{item.text}</S.TextCard>
                </S.WrapperContent>
              </Card>
            </S.Crads>
          </>
        );
      })}
    </S.WrapperCard>
  );
};