import React, { useCallback } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useMyAppointments } from './state/useMyAppointments';
import { AppointmentList } from './components/AppointmentList/AppointmentList';
import { Loader, PageWithCard } from '~/components/@hello-ui';
import { AppointmentDetails } from '~/screens/MyAppointments/components/AppointmentDetails/AppointmentDetails';

export const MyAppointments = (): JSX.Element => {
  const navigation = useNavigation();

  const { myAppointment, myAppointmentLoad, setSelectedAppointment, clearState } =
    useMyAppointments();

  useFocusEffect(
    useCallback(() => {
      clearState();
    }, []),
  );

  const handleGoBack = () => {
    if (myAppointment) {
      setSelectedAppointment(null);
    } else {
      navigation.navigate('Dashboard');
    }
  };

  return (
    <PageWithCard
      simpleOptions={{
        title:
          myAppointment || myAppointmentLoad
            ? ['finished', 'canceled', 'closed'].includes(myAppointment?.status)
              ? 'Histórico'
              : 'Acompanhar Solicitação'
            : 'Meus Agendamentos',
        onBack: handleGoBack,
      }}
      overrideBodyMaxWidth="none"
      overridePadding={{ top: 0, right: 0, bottom: 0, left: 0 }}>
      {myAppointmentLoad ? (
        <Loader variant="circle-loader" />
      ) : myAppointment ? (
        <AppointmentDetails />
      ) : (
        <AppointmentList />
      )}
    </PageWithCard>
  );
};
