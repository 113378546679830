import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { Wrapper } from '~/components/@tem-ui';
import { Button, StyledDefaultTheme } from '~/components/@hello-ui';

export const Container = styled(Wrapper)``;

export const WrapperPage = styled.View`
  width: 100%;
  margin: 0 auto;
  align-items: center;
`;

export const WrapperSearch = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    max-width: ${tokens.sizes.screenSm};
  `}
`;

export const WrapperAvailability = styled.View`
 ${({ theme: { isMobile, tokens } }) => css`
  background-color: white;
  display: flex;
  width: 530px;
  height: 111px;
  gap: 8px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
 
  ${isMobile && 
    css`
    width: 360px;

    `
  }
`}
`;

export const WrapperSchedule = styled.View`
  ${({ theme: { isMobile, tokens } }) => css`
    width: ${tokens.sizes.full};
    max-width: ${tokens.sizes.screenSm};
    justify-content: flex-start;

    ${isMobile &&
    css`
      padding: ${tokens.space.s0} ${tokens.space.s16} ${tokens.space.s16} ${tokens.space.s16};
      margin-top: -20px;
    `}
    ${Platform.OS !== 'web' &&
    css`
      padding: ${tokens.space.s24} ${tokens.space.s16} ${tokens.space.s16} ${tokens.space.s24};
    `}
  `}
`;

export const WrapperInput = styled.View<{ disabled: boolean }>`
  position: relative;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}
`;

export const WrapperSteps = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 24px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
  `}
`;

export const WrapperStepsRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-horizontal: -7px;
`;

export const WrapperStep = styled.View<{ current: boolean }>`
  background-color: ${({ theme: { colors }, current }) => (!current ? colors.blue20 : colors.blue)};
  height: 2px;
  border-radius: 2px;
  flex: 1;
  margin-horizontal: 7px;
`;

export const WrapperButton = styled.View`
  width: 100%;
  max-width: 600px;
  margin-top: 24px;
`;

export const WrapperInfo = styled.View`
  width: 100%;
  display: flex;
  margin-top: ${({ theme: { isMobile, tokens } }) =>
    isMobile ? tokens.space.s12 : tokens.space.s24};
  flex-direction: row;
  align-items: center;
`;

export const ScheduleDateDot = styled.View`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: ${(props) => props.theme.tokens.space.s16};
  background: ${({ theme: { colors } }) => colors.primary};
`;

export const WrapperScheduleExam = styled.View`
  align-items: center;
`;

export const ScheduleWrapperDetail = styled.View<{ noPadding?: boolean }>`
  padding: ${(props) =>
    props.theme.isMobile
      ? `${props.theme.tokens.space.s12} ${props.theme.tokens.space.s16}`
      : `${props.theme.tokens.space.s16} ${props.theme.tokens.space.s24}`};

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0px;
    `};
`;

export const WrapperLoading = styled.View`
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
`;

export const WrapperClinicsList = styled.View`
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const Loading = styled.ActivityIndicator.attrs({
  color: StyledDefaultTheme.colors.primary,
})``;

export const WrapperCheckBox = styled.View<{ isToShowClearHours?: boolean }>`
  display: ${(props) => (!props.theme.isDesktop && !props.isToShowClearHours ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;

  height: 28px;

  margin-top: ${(props) =>
    props.theme.isDesktop ? props.theme.tokens.space.s24 : props.theme.tokens.space.s20};
`;

export const ReviewDetails = styled.View`
  border-color: ${(props) => props.theme.colors.textDisabled};
  border-radius: 8px;
  border-width: 1px;
`;

export const CancelButton = styled(Button)`
  margin-top: ${(props) =>
    props.theme.isMobile ? props.theme.tokens.space.s16 : props.theme.tokens.space.s24};
  padding: 0;
  min-height: 26px;
`;

export const TitleWrapper = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    max-width: ${tokens.sizes.screenSm};
  `}
`;

export const StepperContainer = styled.View`
  min-height: 100%;
`;

export const FullContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;

  min-height: 100%;
`;

export const BodyWrapper = styled.View`
  ${({ theme: { tokens, isMobile } }) => css`
    flex-direction: row;
    width: ${tokens.sizes.full};
    max-width: ${tokens.sizes.screenSm};
    margin-top: ${tokens.space.s16};
    margin-bottom: ${tokens.space.s40};

    ${isMobile &&
    css`
      margin-bottom: ${tokens.space.s16};
    `};
  `}
`;

export const SimpleWrapper = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    max-width: ${tokens.sizes.screenSm};
  `}
`;

export const ButtonWrapper = styled.View`
  ${({ theme: { tokens, platform } }) => css`
    ${platform?.OS === 'web' &&
    css`
      margin-top: ${tokens.space.s40};
    `}
  `}
`;

export const WrapperList = styled.View`
  margin-right: 100px;
`;

export const FixedBottomButton = styled.View`
  ${({ theme: { tokens } }) => css`
    position: absolute;
    bottom: ${tokens.space.s16};
    width: ${tokens.sizes.full};
  `}
`;
