import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { Step } from './types';
import { Button, Dialog, PageWithCard, Typography } from '~/components/@hello-ui';
import SendIcon from '~/components/Icons/SendIcon';
import { Details } from '~/screens/TemPayPassword/components/Details/Details';
import { Token } from '~/screens/TemPayPassword/components/Token/Token';
import { useAuth } from '~/auth/legacy/useAuth';
import { maskTelephone } from '~/utils/strings';
import { Password } from '~/screens/TemPayPassword/components/Password';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';

export const TemPayPassword = (): JSX.Element => {
  const navigation = useNavigation();
  const [step, setStep] = useState<Step>('details');
  const [token, setToken] = useState<{ code: string; validationToken: string }>();
  const [isOrientationModalOpen, setIsOrientationModalOpen] = useState(false);
  const { isDeepLink, feature } = DeepLink.useParams();
  const { user } = useAuth();

  useEffect(() => {
    if (isDeepLink && feature === 'alterar-senha-saude') {
      setStep('details');
    }
  }, []);

  const handleGoBack = () => {
    if (step === 'token') {
      setStep('details');
      return;
    }

    if (step === 'password') {
      setStep('details');
      return;
    }

    if (isDeepLink && feature === 'alterar-senha-saude') {
      DeepLink.sendEvent(DeepLinkEvent.Cancel);
    } else {
      navigation.canGoBack() ? navigation.goBack() : navigation.navigate('TemPayDashboard');
    }
  };

  const openTokenOrientationModal = () => {
    setIsOrientationModalOpen(true);
  };

  const closeTokenOrientationModal = (goNext?: boolean) => {
    return () => {
      setIsOrientationModalOpen(false);
      if (goNext) {
        setStep('token');
      }
    };
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: step === 'details' ? 'Senha da Conta Saúde' : 'Alterar a Senha da Conta Saúde',
        onBack:
          isDeepLink && feature === 'alterar-senha-saude' && step === 'details'
            ? undefined
            : handleGoBack,
      }}>
      {step === 'details' && <Details openTokenOrientationModal={openTokenOrientationModal} />}
      {step === 'token' && <Token setStep={setStep} setToken={setToken} />}
      {step === 'password' && <Password token={token} />}

      <Dialog visible={isOrientationModalOpen} onClose={closeTokenOrientationModal(false)}>
        <View className="flex-1 items-center justify-center">
          <View className="mb-16 mobile:mb-[21px]">
            <SendIcon />
          </View>
          <View className="mb-16 mobile:mb-8">
            <Typography variant="subtitle">Código de Verificação</Typography>
          </View>
          <View className="mb-16">
            <Typography variant="body2" style={{ textAlign: 'center' }}>
              Enviamos para o celular cadastrado um SMS com o código de verificação para validar a
              sua conta
            </Typography>
          </View>
          <View className="mb-40 mobile:mb-32">
            <Typography variant="helperText" style={{ textAlign: 'center' }}>
              Número: {maskTelephone(user?.telephone_1 || user?.telephone_2 || '')}
            </Typography>
          </View>
          <View className="w-full mobile:mb-16">
            <Button variant="primary" onPress={closeTokenOrientationModal(true)}>
              Próximo
            </Button>
          </View>
        </View>
      </Dialog>
    </PageWithCard>
  );
};
