import { ICON_BY_TYPE } from '~/screens/MyAppointments/helpers/icon-by-type';

export const makeInfoByType = () => ({
  bmgOnlineNext: {
    icon: ICON_BY_TYPE.online,
    description: 'Consulta por Vídeo Agendada',
    resume: 'Vídeo',
  },
  bmgOnline: {
    icon: ICON_BY_TYPE.online,
    description: 'Consulta por Vídeo Agendada',
    resume: 'Vídeo',
  },
  online: {
    icon: ICON_BY_TYPE.online,
    description: 'Consulta por Vídeo Agendada',
    resume: 'Vídeo',
  },
  agendamento_em_psicologia_online: {
    icon: ICON_BY_TYPE.online,
    description: 'Consulta por Vídeo Agendada',
    resume: 'Vídeo',
  },
  presential: {
    icon: ICON_BY_TYPE.presential,
    description: 'Consulta Médica',
    resume: 'Presencial',
  },
  medical: {
    icon: ICON_BY_TYPE.presential,
    description: 'Consulta Médica',
    resume: 'Presencial',
  },
  exam: {
    icon: ICON_BY_TYPE.exam,
    description: 'Exames',
    resume: 'Exames e Procedimentos',
  },
  checkup: {
    icon: ICON_BY_TYPE.checkup,
    description: 'Check-Up Médico',
    resume: 'Check-up',
  },
  telemedicine: {
    icon: ICON_BY_TYPE.online,
    description: 'Consulta por Vídeo Agendada',
    resume: 'Vídeo',
  },
});
