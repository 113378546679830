import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const VideoIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24" fill="none">
      <Path
        d="M22 18.172V12C22 11.448 21.552 11 21 11C20.448 11 20 11.448 20 12V18H15.5C15.235 18 14.98 18.105 14.793 18.293L14.086 19H9.914L9.207 18.293C9.019 18.105 8.765 18 8.5 18H4V11C4 9.346 5.346 8 7 8H11C11.552 8 12 7.552 12 7C12 6.448 11.552 6 11 6H7C4.243 6 2 8.243 2 11V18.172C0.836 18.585 0 19.696 0 21C0 22.654 1.346 24 3 24H21C22.654 24 24 22.654 24 21C24 19.696 23.164 18.585 22 18.172ZM21 22H3C2.449 22 2 21.551 2 21C2 20.449 2.449 20 3 20H8.086L8.793 20.707C8.981 20.895 9.235 21 9.5 21H14.5C14.765 21 15.02 20.895 15.207 20.707L15.914 20H21C21.551 20 22 20.449 22 21C22 21.551 21.551 22 21 22ZM21.048 0H16.952C15.324 0 14 1.324 14 2.952V5.048C14 6.543 15.117 7.782 16.561 7.974L17.94 9.115C18.232 9.375 18.599 9.503 18.961 9.503C19.315 9.503 19.666 9.38 19.938 9.138L21.328 7.987C22.825 7.845 24 6.581 24 5.048V2.952C24 1.324 22.676 0 21.048 0ZM22 5.048C22 5.573 21.573 6 21.048 6H20.952C20.719 6 20.494 6.081 20.314 6.23L18.952 7.358L17.589 6.23C17.41 6.082 17.184 6.001 16.951 6.001C16.426 6.001 15.999 5.574 15.999 5.049V2.953C15.999 2.428 16.426 2.001 16.951 2.001H21.047C21.572 2.001 21.999 2.428 21.999 2.953V5.049L22 5.048ZM11.966 16.089C11.989 16.159 12 16.23 12 16.299C11.998 16.672 11.672 17 11.258 17H6.743C6.252 17 5.885 16.54 6.034 16.089C6.434 14.877 7.611 14 9 14C10.389 14 11.566 14.878 11.966 16.089ZM9 13C7.895 13 7 12.105 7 11C7 9.895 7.895 9 9 9C10.105 9 11 9.895 11 11C11 12.105 10.105 13 9 13ZM12 16.299C12 16.23 12.01 16.159 12.034 16.089C12.434 14.877 13.611 14 15 14C16.389 14 17.566 14.878 17.966 16.089C18.115 16.54 17.748 17 17.257 17H12.742C12.328 17 12.002 16.673 12 16.299ZM13 11C13 9.895 13.895 9 15 9C16.105 9 17 9.895 17 11C17 12.105 16.105 13 15 13C13.895 13 13 12.105 13 11Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default VideoIcon;
