import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export const ButtonContainer = styled.TouchableOpacity<{
  disabled: boolean;
  selected: boolean;
  iconPosition?: 'left' | 'right';
  variant: string;
  loader?: boolean;
}>(
  ({ theme, variant, disabled, iconPosition, loader }) => css`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-vertical: ${theme.isMobile ? theme.tokens.space.s12 : theme.tokens.space.s16};
    padding-horizontal: 16px;
    border-radius: 100px;
    width: 100%;

    ${iconPosition === 'right' &&
    css`
      margin-left: 10px;
      margin-right: 0px;
    `}

    ${!loader &&
    variant === 'action' &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
    `}

    ${variant === 'successPrimary' &&
    css`
      /* As the border increases each size of the element by 1px, we will subtract 1px from the padding */
      padding-vertical: ${theme.isMobile ? 11 : 15}px;
      padding-horizontal: 15px;
    `}

  ${variant === 'simple'
      ? css`
          border-width: 1px;
          border-color: ${theme.colors.black5};
          ${theme.tokens.shadows.buttons};
        `
      : variant !== 'text' &&
        variant !== 'outline' &&
        variant !== 'link' &&
        variant !== 'success' &&
        variant !== 'successPrimary' &&
        Platform.select({
          web:
            disabled || variant === 'disabled'
              ? css`
                  box-shadow: 1px 1px 8px rgba(75, 63, 64, 0.15);
                `
              : '',
          android:
            disabled || variant === 'disabled'
              ? css`
                  elevation: 4;
                `
              : '',
          ios:
            disabled || variant === 'disabled'
              ? css`
                  box-shadow: 1px 1px 8px rgba(75, 63, 64, 0.15);
                  shadow-color: rgb(0, 0, 0);
                  shadow-opacity: 0.3;
                  shadow-radius: 3px;
                  elevation: 9;
                `
              : '',
        })}
  `,
);

export const ButtonIcon = styled.View<{ iconPosition?: 'left' | 'right' }>(
  ({ iconPosition }) => css`
    margin-right: ${iconPosition === 'right' ? 0 : 8}px;
    margin-left: ${iconPosition === 'left' ? 0 : 8}px;
  `,
);
