import React, { useEffect, useState } from 'react';

import { View, Platform } from 'react-native';
import * as S from '../styles';
import {
  Typography,
  Button,
  MobileAutoSpace,
} from '~/components/@hello-ui';

import { useAppointment } from '~/hooks/appointment';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { Spacing } from '~/components/Spacing';

const days = [
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sab'
];

const periods = ['Manhã', 'Tarde / Noite'];

export const ScheduleDate = (): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: true });

  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([]);

  const { appointment, setAppointment } = useAppointment();
  const { nextStep } = useStepper();

  return (
    <S.FullContainer>
      <S.TitleWrapper>
        <Typography variant="title">Disponibilidade</Typography>
      </S.TitleWrapper>
      <S.BodyWrapper>
        <Typography variant="body2">
          Nos informe o dia da semana e o melhor período do dia que prefere para o seu agendamento.
          {'\n'}
          <Typography variant="body2">Você pode selecionar mais de um dia e horário.</Typography>
        </Typography>
        
        
      </S.BodyWrapper>
      <View style={{ position: 'relative', maxWidth: 530, minWidth: 111}}>
      <Typography variant='body2'>
        Dia da semana:
      </Typography>
       <S.WrapperAvailability>
        {days.map((item, index) => {
          return <Button 
            key={index}
            variant='selected'
            selected={selectedDays.includes(item)}
            style={{
              display: 'flex',
              margin: 'auto',
              marginRight: 5,
              marginBottom: Platform.OS === 'web' ? 0 : 5,
              flexWrap: 'wrap',
              alignItems: 'center',
              minWidth: 10,
              minHeight: 37,
              maxHeight: 37,
              maxWidth: 90,
              flex: 1,
              flexGrow: 1,
              flexShrink: 2
            }}
            onPress={() => {
              setSelectedDays(selectedDays.includes(item) ? selectedDays.filter((day) => day !== item) : [...selectedDays, item]);
            }}
          >{item}</Button>
        })}
          <Button 
            variant='selected' 
            selected={selectedDays.length === days.length}
            onPress={() => 
              selectedDays.length !== days.length ? 
              setSelectedDays(days) : selectedDays.length === days.length ? 
              setSelectedDays([]) : setSelectedDays(selectedDays)}
            style={{
              display: 'flex',
              margin: 'auto',
              width: '100%',
              marginTop: Platform.OS === 'web' ? -5 : 0,
            }}
          >Todos os dias</Button>
      </S.WrapperAvailability>
      <Spacing top={Platform.OS === 'web' ? 15 : 0}> 
      <Typography variant='body2'>
            Período do dia:
          </Typography>
          <S.WrapperAvailability>
              {periods.map((item, index) => {
              return <Button 
                key={index}
                variant='selected' 
                selected={selectedPeriods.includes(item)}
                style={{
                  display: 'flex',
                  margin: 'auto',
                  marginRight: 5,
                  marginBottom: 5,
                  width: '50%',
                  flexWrap: 'wrap',
                  textAlign: 'center',
                  alignItems: 'center',
                  flex: 1,
                  flexGrow: 1,
                  flexShrink: 2
                }}
                onPress={() => {
                  setSelectedPeriods(selectedPeriods.includes(item) ? selectedPeriods.filter((day) => day !== item) : [...selectedPeriods, item]);
                }}>{item}</Button>
            })}
            <Button variant='selected' 
          selected={selectedPeriods.length === periods.length}
          onPress={() => selectedPeriods.length !== periods.length ? setSelectedPeriods(periods) : selectedPeriods.length === periods.length ? setSelectedPeriods([]) : setSelectedPeriods(selectedPeriods)}
            style={{
                  display: 'flex',
                  margin: 'auto',
                  marginTop: Platform.OS === 'web' ? -5 : 0,
                  width: '100%'
                }}>Todos os períodos</Button>
          </S.WrapperAvailability>
        </Spacing>
     
      </View>
     
      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Button
        disabled={selectedDays.length === 0 || selectedPeriods.length === 0}
        variant={'primary'}
        onPress={() => {
          setAppointment({ ...appointment, days: [selectedDays, selectedPeriods]});
          nextStep();
        }}>
        Próximo
      </Button>
    </S.FullContainer>
  );
};
