import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as yup from 'yup';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { Button, Input, MobileAutoSpace, Typography, useSweetAlert } from '~/components/@hello-ui';
import { useTokenSMS } from '~/components/@tem-ui/TokenSMS';
import { Step } from '~/screens/TemPayPassword/types';
import { ReSendCode } from '~/screens/Login/components/ReSendCode';
import { maskTelephone } from '~/utils/strings';

const schema = yup.object().shape({
  token: yup.string().required('Campo obrigatório').min(4, 'Preencha os 4 dígitos'),
});

interface TokenProps {
  setStep: Dispatch<SetStateAction<Step>>;
  setToken: Dispatch<
    SetStateAction<
      | {
          code: string;
          validationToken: string;
        }
      | undefined
    >
  >;
}

export const Token: React.FC<TokenProps> = ({ setStep, setToken }) => {
  const api = useApi();
  const { user } = useAuth();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { getSMSHash } = useTokenSMS();
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    formState: { isValid: isValidForm, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    void onGetToken();
  }, []);

  const onGetToken = useCallback(async () => {
    try {
      const smsHash = await getSMSHash();
      const {
        data: { validationToken },
      } = await api.sendToken(user.cpf, undefined, smsHash);

      if (validationToken) {
        await AsyncStorage.setItem('@tem::ValidationToken', validationToken);
      }
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível obter o token.',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  }, [user]);

  const onNext = async (values) => {
    try {
      const { data } = await api.checkToken(Number(values.token));

      if (data.isValid) {
        setToken({
          validationToken: (await AsyncStorage.getItem('@tem::ValidationToken')) ?? '',
          code: String(values.token),
        });
        setStep('password');
      } else {
        throw new Error();
      }
    } catch (error) {
      showSweetAlert('Ops, algo deu errado', 'Código inválido', 'error', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'Ok',
            variant: 'primary',
            onPress: () => hideSweetAlert(),
          },
        ],
      });
    }
  };

  const resend = () => {
    void onGetToken();
    showSweetAlert('Tudo certo!', 'Um novo código de acesso foi enviado', 'success', false, false, {
      layout: 'helloUi',
      touchOutside: false,
      buttons: [
        {
          text: 'Ok',
          variant: 'primary',
          onPress: () => hideSweetAlert(),
        },
      ],
    });
  };

  return (
    <View className="flex-1 mobile:mt-56">
      <View className="mb-8 mobile:mb-16">
        <Typography variant={theme.isMobile ? 'body1' : 'body2'}>
          Digite o código de verificação enviado por SMS para o celular cadastrado
        </Typography>
      </View>
      <View className="mb-40 mobile:mb-32">
        <Typography variant="helperText">
          Número: {maskTelephone(user?.telephone_1 || user?.telephone_2 || '')}
        </Typography>
      </View>

      <Controller
        name="token"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Input
            variant="code"
            value={value}
            onChangeText={onChange}
            isAutoFocus
            label="Código de acesso"
            keyboardType="number-pad"
          />
        )}
      />
      <MobileAutoSpace heightMobile={40} heightDesktop={100} />
      <View className="mb-24">
        <Button
          disabled={!isValidForm || isSubmitting}
          variant="primary"
          onPress={handleSubmit(onNext)}
          loading={isSubmitting}>
          Próximo
        </Button>
      </View>
      <ReSendCode onPress={resend} />
    </View>
  );
};
