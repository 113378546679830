import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

const CheckUpIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24" fill="none">
      <G clip-path="url(#clip0_19359_16280)">
        <Path
          d="M5 4C5 3.448 5.448 3 6 3H8V1C8 0.448 8.448 0 9 0C9.552 0 10 0.448 10 1V3H12C12.552 3 13 3.448 13 4C13 4.552 12.552 5 12 5H10V7C10 7.552 9.552 8 9 8C8.448 8 8 7.552 8 7V5H6C5.448 5 5 4.552 5 4ZM23.236 13.015L16.431 20.652C14.535 22.78 11.814 24 8.965 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H12.858C13.997 11 14.996 11.609 15.547 12.518L18.763 8.984C19.305 8.389 20.045 8.04 20.849 8.003C21.652 7.958 22.422 8.243 23.017 8.785C24.231 9.892 24.329 11.789 23.236 13.014V13.015ZM21.67 10.264C21.471 10.083 21.211 9.997 20.942 10.002C20.672 10.015 20.424 10.131 20.242 10.331L15.816 15.195C15.431 16.266 14.477 17.086 13.302 17.254L8.141 17.991C7.596 18.07 7.088 17.69 7.01 17.143C6.932 16.596 7.312 16.09 7.858 16.012L13.019 15.275C13.578 15.195 14 14.709 14 14.144C14 13.514 13.488 13.002 12.858 13.002H4C2.897 13.002 2 13.899 2 15.002V20.002C2 21.105 2.897 22.002 4 22.002H8.965C11.244 22.002 13.422 21.026 14.938 19.324L21.743 11.686C22.11 11.274 22.077 10.637 21.67 10.265V10.264Z"
          fill={color ?? theme.colors.primary}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_19359_16280">
          <Rect width={width ?? '24'} height={height ?? '24'} fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default CheckUpIcon;
