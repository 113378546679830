import React, { useEffect, useRef } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { PageWithCardProps } from '~/components/@hello-ui/PageWithCard/types';
import { PageWithCardProvider } from '~/components/@hello-ui/PageWithCard/context/PageWithCardContext';
import { CustomKeyboardAvoidingView } from '~/components/CustomKeyboardAvoidingView/CustomKeyboardAvoidingView';
import { PageScroll } from '~/components/@hello-ui/PageWithCard/components/PageScroll';
import { Page } from '~/components/@hello-ui/PageWithCard/components/Page';
import { PageLimit } from '~/components/@hello-ui/PageWithCard/components/PageLimit';
import { BodyScroll } from '~/components/@hello-ui/PageWithCard/components/BodyScroll';
import { ContentLimit } from '~/components/@hello-ui/PageWithCard/components/ContentLimit';
import { HeaderSimple } from '~/components/@hello-ui/PageWithCard/components/HeaderSimple';

export const PageWithCardSimple: React.FC<PageWithCardProps> = ({
  overridePadding,
  onScrollViewRefUpdate,
  overrideBodyMaxWidth,
  overrideScrollEnabled,
  overridePageMaxWidth,
  overrideHeader,
  simpleOptions,
  children,
}) => {
  const { isMobile } = useTheme();

  const pageScrollViewRef = useRef<ScrollView>(null);
  const bodyScrollRef = useRef<ScrollView>(null);

  const { onBack, titleStartContent, title, onHome } = simpleOptions ?? {};

  useEffect(() => {
    onScrollViewRefUpdate?.(isMobile ? bodyScrollRef : pageScrollViewRef);
  }, [isMobile]);

  const getScrolls = () => {
    return isMobile ? { body: true, page: false } : { body: false, page: true };
  };

  const { body, page } = getScrolls();

  return (
    <PageWithCardProvider
      pageScroll={pageScrollViewRef}
      bodyScroll={bodyScrollRef}
      currentScroll={isMobile ? bodyScrollRef : pageScrollViewRef}>
      <CustomKeyboardAvoidingView>
        <PageScroll
          contentStyle={
            isMobile
              ? {
                  maxHeight: Platform.OS === 'web' ? '100dvh' : '100%',
                }
              : {}
          }
          scrollEnabled={
            page ? (overrideScrollEnabled !== undefined ? overrideScrollEnabled : true) : false
          }
          ref={pageScrollViewRef}>
          {Platform.OS === 'web' && !isMobile && (
            <View
              className="top-0 z-10 -mb-40 h-40 w-full bg-background-gray"
              style={{ position: 'sticky' } as never}
            />
          )}
          <Page>
            <PageLimit overridePageMaxWidth={overridePageMaxWidth}>
              {overrideHeader ? (
                overrideHeader()
              ) : (
                <HeaderSimple
                  title={title!}
                  onBack={onBack}
                  onHome={onHome}
                  titleStartContent={titleStartContent}
                />
              )}
              <BodyScroll
                ref={bodyScrollRef}
                scrollEnabled={
                  body
                    ? overrideScrollEnabled !== undefined
                      ? overrideScrollEnabled
                      : true
                    : false
                }
                overridePadding={overridePadding}>
                <ContentLimit overrideBodyMaxWidth={overrideBodyMaxWidth}>{children}</ContentLimit>
              </BodyScroll>
            </PageLimit>
          </Page>
        </PageScroll>
      </CustomKeyboardAvoidingView>
    </PageWithCardProvider>
  );
};
